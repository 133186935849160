<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="bottom-tabbar">
        <ion-tab-button tab="tab1" @click="changeBtn(0)">
          <!-- <ion-icon :icon="homeOutline"/> -->
          <img v-if="currentIndex === 0" class="pic" src="../assets/tab-home-active.png" alt="" />
          <img v-else class="pic" src="../assets/tab-home.png" alt="" />
          <ion-label class="label" :class="{ active: currentIndex === 0 }">首页</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" @click="changeBtn(1)">
          <!-- <ion-icon :icon="timerOutline"/> -->
          <img v-if="currentIndex === 1" class="pic" src="../assets/tab-classrecord-active.png" alt="" />
          <img v-else class="pic" src="../assets/tab-classrecord.png" alt="" />
          <ion-label class="label" :class="{ active: currentIndex === 1 }">训练记录</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" @click="changeBtn(2)">
          <!-- <ion-icon :icon="peopleOutline"/> -->
          <img v-if="currentIndex === 2" class="pic" src="../assets/tab-studentinfo-active.png" alt="" />
          <img v-else class="pic" src="../assets/tab-studentinfo.png" alt="" />
          <ion-label class="label" :class="{ active: currentIndex === 2 }">用户信息</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" @click="changeBtn(3)">
          <img v-if="currentIndex === 3" class="pic" src="../assets/tab-mine-active.png" alt="" />
          <img v-else class="pic" src="../assets/tab-mine.png" alt="" />
          <ion-label class="label" :class="{ active: currentIndex === 3 }">我的</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>


<script setup lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet } from "@ionic/vue";
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
const currentIndex = ref(0);
const router = useRouter();
const route = useRoute();
const pathList = ["/TeacherIndex", "/ClassRecord", "/StudentList", "/TeacherPersonalIndex"];
function changeBtn(type: number) {
  currentIndex.value = type;
  router.push(pathList[type]);
}
watch(
    () => route.path,
    (newVal) => {
      currentIndex.value = pathList.indexOf(newVal);
    },
    { deep: true, immediate: true }
);
</script>

<style scoped>
.bottom-tabbar {
  height: 15vmin;
  display: flex;
  justify-content: space-around;
  border-top:1px #e8e8e8 solid;
}

.pic {
  width: 7vmin;
}
.label {
  color: #868f9e;
  margin-top:0.5vh;
}
.active {
  color: #000000;
}

@media screen and (min-width: 600px) {
  .label {
    font-size: 2.5vmin;
  }
}
</style>