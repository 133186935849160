import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';

import TeacherTabs from '../views/TeacherTabs.vue'
import StudentTabs from '../views/StudentTabs.vue'
import MergeTabs from '../views/merge/MergeTabs.vue'
import Register from '../views/Register.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/Login',
    },

    //登录
    {
        path: '/Login',
        component: () => import('@/views/Login.vue')
    },

    {
        path: '/TeacherScheduleProfile',
        component: () => import('@/views/personal/TeacherScheduleProfile.vue')
    },

    //注册
    {
        path: '/Register',
        component: Register
    },

    //补充完善信息
    {
        path: '/Supplement',
        component: () => import('@/views/Supplement.vue')
    },

    //用户反馈页
    {
        path: '/StudentFeedback',
        component: () => import('@/views/other/StudentFeedback.vue')
    },

    //单词速记
    //speed
    {
        path: '/speed/Review/:studentId/:mastery',
        component: () => import('@/views/speed/Review.vue'),
        props: true
    },

    //reading
    {
        path: '/reading/Index',
        component: () => import('@/views/reading/Index.vue'),
        children: [

            //陪练备训练，阅读理解列表
            {
                path: '/reading/IndexPrepare',
                component: () => import('@/views/reading/IndexPrepare.vue'),
                props: true
            }
        ]
    },

    {
        path: '/listening/Index',
        component: () => import('@/views/listening/Index.vue')
    },

    //强行提交练习结果
    {
        path: '/AfterTest/:studentId',
        component: () => import('@/views/speed/AfterTest.vue'),
        props: true
    },

    //合并
    {
        path: '/merge',
        redirect: '/MergeIndex',
        component: MergeTabs,
        children: [

            //首页
            {
                path: '/MergeIndex',
                component: () => import('@/views/merge/MergeIndex.vue'),
                props: true
            },

            //练习记录
            {
                path: '/MergeClassRecord',
                component: () => import('@/views/merge/MergeClassRecord.vue'),
                props: true
            },

            //个人中心
            {
                path: '/MergePersonalIndex',
                component: () => import('@/views/merge/MergePersonalIndex.vue')
            },

        ]
    },

    //陪练
    {
        path: '/teacher',
        redirect: '/TeacherIndex',
        component: TeacherTabs,
        children: [

            //陪练首页
            {
                path: '/TeacherIndex',
                component: () => import('@/views/TeacherIndex.vue'),
                props: true
            },

            //学员管理
            {
                path: '/StudentList',
                component: () => import('@/views/other/StudentList.vue'),
                props: true
            },

            //练习记录
            {
                path: '/ClassRecord',
                component: () => import('@/views/other/ClassRecord.vue'),
                props: true
            },

            //个人中心
            {
                path: '/TeacherPersonalIndex',
                component: () => import('@/views/personal/TeacherPersonalIndex.vue')
            },

        ]
    },

    {
        path: '/student',
        component: StudentTabs,
        children: [
            {
                path: '/StudentIndex',
                component: () => import('@/views/StudentIndex.vue')
            },
            {
                path: '/StudentRanking',
                component: () => import('@/views/other/StudentRanking.vue'),
                props: true
            },
            {
                path: '/PersonalClassRecord',
                component: () => import('@/views/personal/PersonalClassRecord.vue'),
                props: true
            },
            {
                path: '/Progress',
                component: () => import('@/views/personal/Progress.vue'),
                props: true
            },
            {
                path: '/StudentPersonalIndex',
                component: () => import('@/views/personal/StudentPersonalIndex.vue'),
                props: true
            },
        ]
    },

    {
        path: '/personal/MyLessonsInfo',
        component: () => import('@/views/personal/MyLessonsInfo.vue'),
        props: true
    },

    // ai
    {
        path: '/ai-generate/:value',
        component: () => import('@/views/AIGenerate/AIGenerate.vue'),
    }



]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
