/* eslint-disable */

//李校来啦 https 生产环境 226
function liXiao_226_https() {
    return {
        brand: "LIXIAO",                         //品牌
        hostname: '127.0.0.1',                   //域名
        API_ADDRESS: "https://lx-prod-api.yingyutifen.cn:6443",      //服务器地址
        WSS_ADDRESS: "wss://lx-prod-api.yingyutifen.cn:6443/myWs",   //websocket服务器地址
        PRINT_ADDRESS: "https://print.yingyutifen.cn",       //服务器地址
    }
}

//李校来啦 直属  215
function liXiao_head_215_https() {
    return {
        brand: "LIXIAO",                         //品牌
        hostname: '127.0.0.1',                   //域名
        API_ADDRESS: "https://lx-prod-head-api.yingyutifen.cn",      //服务器地址
        WSS_ADDRESS: "wss://lx-prod-head-api.yingyutifen.cn/myWs",   //websocket服务器地址
        PRINT_ADDRESS: "https://print.yingyutifen.cn",       //服务器地址
    }
}

//李校来啦 226
function liXiao_226_pre() {
    return {
        brand: "LIXIAO",               //品牌
        hostname: '127.0.0.1',         //域名
        API_ADDRESS: "http://116.62.194.226:24710",       //服务器地址
        WSS_ADDRESS: "ws://116.62.194.226:24710/myWs",    //websocket服务器地址
        PRINT_ADDRESS: "https://print.yingyutifen.cn",    //服务器地址
    }
}

//本地服务器
function local() {
    return {
        brand: "YES",                         //品牌
        hostname: '127.0.0.1',              //域名
        API_ADDRESS: "http://127.0.0.1:60318",            //服务器地址
        WSS_ADDRESS: "ws://127.0.0.1:60318/myWs",         //websocket服务器地址
        PRINT_ADDRESS: "http://47.110.141.208:23130",    //服务器地址
    }
}

//本地服务器
function local_zgq() {
    return {
        brand: "YES",                         //品牌
        hostname: '127.0.0.1',               //域名
        API_ADDRESS: "http://127.0.0.1:60315",            //服务器地址
        WSS_ADDRESS: "ws://127.0.0.1:60315/myWs",         //websocket服务器地址
        PRINT_ADDRESS: "http://47.110.141.208:23130",    //服务器地址
    }
}

function localCompany() {
    return {
        brand: "YES",                         //品牌
        hostname: '127.0.0.1',              //域名
        API_ADDRESS: "http://192.168.2.8:60314",            //服务器地址
        WSS_ADDRESS: "ws://192.168.2.8:60314/myWs",         //websocket服务器地址
        PRINT_ADDRESS: "http://47.110.141.208:23130",    //服务器地址
    }
}

//李校来啦 3.0 test ip + port
function lx3_test() {
    return {
        brand: "LIXIAO",                         //品牌
        hostname: '127.0.0.1',
        VERIFY_ADDRESS: "http://47.110.141.208:60315",    //登录图形验证
        API_ADDRESS: "http://47.110.141.208:61300",       //服务器地址
        WSS_ADDRESS: "ws://47.110.141.208:61300/myWs",    //websocket服务器地址
        PRINT_ADDRESS: "http://47.110.141.208:23130",     //服务器地址
    }
}

//耶思https
function yesHttps() {
    return {
        brand: "YES",                         //品牌
        hostname: '120.79.206.195',              //域名
        API_ADDRESS: "https://api.edu-yes.cn",      //服务器地址
        WSS_ADDRESS: "wss://api.edu-yes.cn/myWs",   //websocket服务器地址
        PRINT_ADDRESS: "https://print.edu-yes.cn",       //服务器地址
    }
}

//耶思
function yes_pre() {
    return {
        brand: "YES",                         //品牌
        hostname: '120.79.206.195',              //域名
        API_ADDRESS: "http://120.79.206.195:60316",       //服务器地址
        WSS_ADDRESS: "ws://120.79.206.195:60316/myWs",    //websocket服务器地址
        PRINT_ADDRESS: "https://print.edu-yes.cn",     //服务器地址
    }
}
function danciwang_pre() {
    return {
        brand: "zhengzhou",                         //品牌
        hostname: '8.154.31.197',              //域名
        API_ADDRESS: "http://8.154.31.197:60314",       //服务器地址
        WSS_ADDRESS: "ws://8.154.31.197:60314/myWs",    //websocket服务器地址
        PRINT_ADDRESS: "http://8.154.31.197:8446",     //服务器地址
    }
}
//李校来啦 https 生产环境 226
function lx3_slb_https() {
    return {
        brand: "LIXIAO",                         //品牌
        hostname: '127.0.0.1',                   //域名
        API_ADDRESS: "https://lx3-api.yingyutifen.cn",      //服务器地址
        WSS_ADDRESS: "wss://lx3-api.yingyutifen.cn/myWs",   //websocket服务器地址
        PRINT_ADDRESS: "https://print.yingyutifen.cn",       //服务器地址
    }
}

function lx3_slbtest_https() {
    return {
        brand: "LIXIAO",                         //品牌
        hostname: '127.0.0.1',                   //域名
        VERIFY_ADDRESS: "http://47.110.141.208:60315",    //登录图形验证
        API_ADDRESS: "http://47.110.141.208:61300",       //服务器地址
        WSS_ADDRESS: "ws://47.110.141.208:61300/myWs",    //websocket服务器地址
        PRINT_ADDRESS: "http://47.110.141.208:23130",     //服务器地址
    }
}
// local
// lx3_test
// liXiao_226_pre
// liXiao_226_https
//liXiao_head_215_https

export default {
    version: "1.0.0", //APP版本
    config:danciwang_pre(),
}
